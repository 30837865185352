.App {
  text-align: center;
}

.buttons {
  display: flex;
  width: 600px;
  padding: 50px;
}

.button {
  border: 1px solid #999;
  padding: 20px;
}
